'use strict';

angular.module('enervexSalesappApp').controller('ResetCtrl', function($scope, Auth, Flash, $stateParams, _) {
	$scope.user = {};
	$scope.errors = {};
	$scope.token = $stateParams.token;


	$scope.reset_password = function(form) {
		$scope.submitted = true;
		$scope.success = false;

		if (form.$valid) {
			Flash.clear();
			Auth.login({
				email:$scope.token,
				password: $scope.user.password,
				istoken: true
			}).then(function(data) {
				$scope.success = true;
				var message = '<strong>Success!</strong> Your password has been reset.  Redirecting to <a href="/accounts">view accounts</a>.';
				Flash.create('success', message, 0, {}, true);
				setTimeout(function() {
					window.location.href = "/accounts"
				}, 2500)
			}).catch(function(response) {
				var err = response.data
				
				// $scope.errors.other = err.message;
				var message = err.message || response.statusText;
				Flash.create('danger', '<strong>Error!</strong> '+message, 0, {}, true);
				$scope.errors = {};
				if (err && err.errors){
					// Update validity of form fields that match the mongoose errors
					_.each(err.errors, function(error, field) {
						// form[field].$setValidity('mongoose', false);
						$scope.errors[field] = error.message;
					});
				}
			});
		}
	};
});
